<template>
  <v-row class="social-auth" justify="center">
    <v-col cols="12">
      <v-btn block color="app-red" @click="socialAuth('google')" dark>
        <v-icon left class="mr-2">fab fa-google</v-icon>
        {{ this.$i18n.t(`auth.${authType}_google`) }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import hello from 'hellojs';
import { SOCIAL_AUTH } from '../../store/auth/types';

export default {
  name: 'social-auth',
  props: {
    authType: {
      type: String,
      default: 'login'
    }
  },
  created() {
    hello.init({
      google: process.env.VUE_APP_GOOGLE_CLIENT_ID
    });
  },
  methods: {
    async socialAuth(network) {
      try {
        await hello(network).logout();
      } catch (e) {} // eslint-disable-line

      try {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/login`; // eslint-disable-line

        let auth = await hello(network).login({
          scope: 'email',
          redirect_uri: redirectUrl // eslint-disable-line camelcase
        });

        const result = await this.$store.dispatch(SOCIAL_AUTH, {
          network: auth.network,
          accessToken: auth.authResponse.access_token
        });
        this.$i18n.locale = result.user.lang;

        this.$router.push({ path: '/dashboard' });

        // await this.finishAuth();
      } catch (err) {
        // logout the user
        await hello(network).logout();
        this.socialAuth(network); // login again

        if (err.message) {
          this.$notify({
            type: 'error',
            text: this.$i18n.t(`errors.${err.message}`)
          });
        }
      }
    }
  }
};
</script>
